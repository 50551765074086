.category-title{
    width:65vw;
    background-color: #3d5c7b;
    color: #dedede;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    margin: 10px auto;

}

@media (max-device-width: 600px) {
    .category-title{
        width:80vw;
        background-color: #5b6361;
        color: #dedede;
        padding: 15px 20px 15px 20px;
        font-weight: bold;
        margin: 10px auto;

    }
}